import React, { createRef } from "react";
import {
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  Loader,
  Nav,
  PaginationClass,
} from "ui-kit-ck-consultant";

import ShowOptionsNGRA from "../../components/general/ShowOptionsNGRA-v2";
import Folder from "../../components/satisfaction/Folder";
import Global from "../../components/satisfaction/Global";
import Evolution from "../../components/satisfaction/Evolution";
import Home from "../../components/satisfaction/Home";
import Allianz from "../../components/satisfaction/Allianz";
import Identification from "../../components/satisfaction/Identification";
import List from "../../components/satisfaction/List";
import PrintClass from "../../components/general/Print";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

import {
  getNPS,
  getAverageGrade,
  getResponseRate,
  getData,
} from "../../requests/satisfaction";
export default class Satisfaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: 0,
      isLoadPagination: false,
      isPrint: false,
      // data search
      dateStart: null,
      dateEnd: null,
      network: null,
      group: null,
      region: null,
      agency: null,
      company: null,
      // NPS data
      NPSData: null,
      NPSDayData: null,
      // Average grade data
      averageGradeData: null,
      averageGradeDayData: null,
      // Response rate data
      responseRateData: null,
      responseRateDayData: null,
      responseCountData: null,
      responseCountDayData: null,
      // General data
      generalSatisfactionData: null,
      generalReparableData: null,
      generalTypesData: null,
      generalLocationsData: null,
      generalCompaniesData: null,
      generalDataChart: null,
      // List data
      activePage: 1,
      tabLength: 20,
      total: 0,
      listData: [],
      order: null,
      isAll: false,
      // Tabs
      tabsMenu: ["Géneral", "ALLIANZ"],
      tabsIndex: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    if (this.context.network !== 1 && this.context.network) {
      this.setState({ tabsMenu: ["Géneral"] });
    }

    this.PaginationClass = new PaginationClass(
      "satisfaction/count",
      "satisfaction/pagination",
      this.state.tabLength
    );
  }

  handleDataChange = (data) => {
    this.isLoad = 7;

    this.setState(
      {
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        company: data.company,
        isLoad: this.isLoad,
        tabsMenu:
          !data.company || data.company === 1
            ? this.context.network !== 1 && this.context.network
              ? ["Géneral"]
              : ["Géneral", "ALLIANZ"]
            : ["Géneral"],
      },
      () => {
        getNPS(this.getData(), (result) => {
          this.isLoad--;
          if (result.success) {
            this.setState({
              NPSData: result.data,
              isLoad: this.isLoad,
            });
          } else {
            this.setState({ NPSData: null, isLoad: this.isLoad });
          }
        });

        getNPS(
          this.getData(),
          (result) => {
            this.isLoad--;
            if (result.success) {
              this.setState({
                NPSDayData: result.data,
                isLoad: this.isLoad,
              });
            } else {
              this.setState({ NPSDayData: null, isLoad: this.isLoad });
            }
          },
          true
        );

        getAverageGrade(this.getData(), (result) => {
          this.isLoad--;
          if (result.success) {
            this.setState({
              averageGradeData: result.data,
              isLoad: this.isLoad,
            });
          } else {
            this.setState({
              averageGradeData: null,
              isLoad: this.isLoad,
            });
          }
        });

        getAverageGrade(
          this.getData(),
          (result) => {
            this.isLoad--;
            if (result.success) {
              this.setState({
                averageGradeDayData: result.data,
                isLoad: this.isLoad,
              });
            } else {
              this.setState({
                averageGradeDayData: null,
                isLoad: this.isLoad,
              });
            }
          },
          true
        );

        getResponseRate(this.getData(), (result) => {
          this.isLoad--;
          if (result.success) {
            this.setState({
              responseRateData: result.data,
              responseCountData: result.count,
              isLoad: this.isLoad,
            });
          } else {
            this.setState({
              responseRateData: null,
              responseCountData: null,
              isLoad: this.isLoad,
            });
          }
        });

        getResponseRate(
          this.getData(),
          (result) => {
            this.isLoad--;
            if (result.success) {
              this.setState({
                responseRateDayData: result.data,
                responseCountDayData: result.count,
                isLoad: this.isLoad,
              });
            } else {
              this.setState({
                responseRateDayData: null,
                responseCountDayData: null,
                isLoad: this.isLoad,
              });
            }
          },
          true
        );

        getData(this.getData(), (result) => {
          this.isLoad--;
          if (result.success) {
            this.setState({
              generalSatisfactionData: result.satisfaction,
              generalReparableData: result.reparable,
              generalTypesData: result.types,
              generalLocationsData: result.locations,
              generalCompaniesData: result.companies,
              generalDataChart: result.dataChart,
              isLoad: this.isLoad,
            });
          } else {
            this.setState({
              generalSatisfactionData: null,
              generalReparableData: null,
              generalTypesData: null,
              generalLocationsData: null,
              generalCompaniesData: null,
              generalDataChart: null,
              isLoad: this.isLoad,
            });
          }
        });

        this.initSearch();
      }
    );
  };

  // List data

  initSearch = (reference = "") => {
    this.setState({ activePage: 1, isLoadPagination: true }, () => {
      this.PaginationClass.init(
        axiosConfig,
        this.getData(reference),
        (result) => {
          if (result.success === true) {
            this.setState({ total: result.total }, () => {
              this.PaginationClass.get(
                axiosConfig,
                this.getData(reference),
                (result) => {
                  this.setData(result);
                }
              );
            });
          } else {
            this.setState({
              isLoadPagination: false,
            });
          }
        }
      );
    });
  };

  getData(reference) {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      company: this.state.tabsIndex === 1 ? 1 : this.state.company,
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
      reference,
    };
  }

  setData(result) {
    if (result.success === true) {
      this.setState({
        listData: result.tabData,
        isLoadPagination: false,
      });
    } else {
      this.setState({
        listData: [],
        isLoadPagination: false,
      });
    }
  }

  handlePageChange = (activePage) => {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage, isLoadPagination: true }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          this.setData(result);
        });
      })
    );
  };

  handleSortClick = (order) => {
    this.setState({ order }, () => {
      this.initSearch();
    });
  };

  chunk(items, size) {
    const chunks = [];
    items = [].concat(...items);

    while (items.length) {
      chunks.push(items.splice(0, size));
    }

    return chunks;
  }

  render() {
    this.refPrint = [
      createRef(),
      createRef(),
      createRef(),
      createRef(),
      createRef(),
    ];
    this.refPrintAllianz = [createRef(), createRef()];

    const listData = this.chunk(this.state.listData, this.state.tabLength);
    listData.forEach(() => {
      this.refPrint.push(createRef());
      this.refPrintAllianz.push(createRef());
    });

    return (
      <>
        <Nav className="mb-10 mb-20-xl" title="Qualité Client" />
        <ShowOptionsNGRA
          isChange={true}
          className="mb-10 mb-20-xl"
          onDataChange={this.handleDataChange}
          isDateStart={true}
          isDateEnd={true}
          isCompany={true}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          isAll={true}
          userType={[1, 2, 3]}
          disableForceDate
        />

        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                title={element}
                active={this.state.tabsIndex === idx}
                disabled={!this.state.tabsMenu[idx]}
                onClick={(e) => {
                  this.setState(
                    {
                      tabsIndex: idx,
                    },
                    () => this.handleDataChange(this.state)
                  );
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              <div ref={this.refPrint[0]}>
                <Global
                  className="mb-10 mb-20-xl"
                  count={0}
                  NPSData={this.state.NPSData}
                  NPSDayData={this.state.NPSDayData}
                  averageGradeData={this.state.averageGradeData}
                  averageGradeDayData={this.state.averageGradeDayData}
                  responseRateData={this.state.responseRateData}
                  responseRateDayData={this.state.responseRateDayData}
                  responseCountData={this.state.responseCountData}
                  responseCountDayData={this.state.responseCountDayData}
                  generalSatisfactionData={this.state.generalSatisfactionData}
                  isPrint={this.state.isPrint}
                />
              </div>
              <div ref={this.refPrint[1]}>
                <Evolution
                  className="mb-10 mb-20-xl"
                  isPrint={this.state.isPrint}
                  generalDataChart={this.state.generalDataChart}
                />
              </div>
              <div ref={this.refPrint[2]}>
                <Home
                  className="mb-10 mb-20-xl"
                  generalSatisfactionData={this.state.generalSatisfactionData}
                  isPrint={this.state.isPrint}
                />
              </div>
              <div ref={this.refPrint[3]}>
                <Folder
                  className="mb-10 mb-20-xl"
                  generalSatisfactionData={this.state.generalSatisfactionData}
                  isPrint={this.state.isPrint}
                />
              </div>
              <div ref={this.refPrint[4]}>
                <Identification
                  className="mb-10 mb-20-xl"
                  generalReparableData={this.state.generalReparableData}
                  generalTypesData={this.state.generalTypesData}
                  generalLocationsData={this.state.generalLocationsData}
                  generalCompaniesData={this.state.generalCompaniesData}
                  isPrint={this.state.isPrint}
                />
              </div>
              {listData.map((list, idx) => (
                <div ref={this.refPrint[5 + idx]}>
                  <List
                    key={idx}
                    className="mb-10 mb-20-xl"
                    handlePageChange={this.handlePageChange}
                    handleSortClick={this.handleSortClick}
                    activePage={this.state.activePage}
                    tabLength={this.state.tabLength}
                    total={this.state.total}
                    listData={list}
                    isPrint={this.state.isPrint}
                    initSearch={this.initSearch}
                    onListAll={() =>
                      this.setState(
                        {
                          tabLength: this.state.isAll ? 20 : 40,
                          isAll: !this.state.isAll,
                        },
                        () => {
                          this.PaginationClass = new PaginationClass(
                            "satisfaction/count",
                            "satisfaction/pagination",
                            this.state.tabLength,
                            this.state.isAll
                          );

                          this.initSearch();
                        }
                      )
                    }
                    isAll={this.state.isAll}
                  />
                </div>
              ))}
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              <div ref={this.refPrintAllianz[0]}>
                <Global
                  className="mb-10 mb-20-xl"
                  count={0}
                  NPSData={this.state.NPSData}
                  NPSDayData={this.state.NPSDayData}
                  averageGradeData={this.state.averageGradeData}
                  averageGradeDayData={this.state.averageGradeDayData}
                  responseRateData={this.state.responseRateData}
                  responseRateDayData={this.state.responseRateDayData}
                  responseCountData={this.state.responseCountData}
                  responseCountDayData={this.state.responseCountDayData}
                  generalSatisfactionData={this.state.generalSatisfactionData}
                  isPrint={this.state.isPrint}
                  hideNPS={true}
                />
              </div>
              <div ref={this.refPrintAllianz[1]}>
                <Allianz
                  className="mb-10 mb-20-xl"
                  generalSatisfactionData={this.state.generalSatisfactionData}
                  isPrint={this.state.isPrint}
                />
              </div>
              {listData.map((list, idx) => (
                <div ref={this.refPrintAllianz[2 + idx]}>
                  <List
                    key={idx}
                    className="mb-10 mb-20-xl"
                    handlePageChange={this.handlePageChange}
                    handleSortClick={this.handleSortClick}
                    activePage={this.state.activePage}
                    tabLength={this.state.tabLength}
                    total={this.state.total}
                    listData={list}
                    isPrint={this.state.isPrint}
                    onListAll={() =>
                      this.setState(
                        {
                          tabLength: this.state.isAll ? 20 : 40,
                          isAll: !this.state.isAll,
                        },
                        () => {
                          this.PaginationClass = new PaginationClass(
                            "satisfaction/count",
                            "satisfaction/pagination",
                            this.state.tabLength,
                            this.state.isAll
                          );

                          this.initSearch();
                        }
                      )
                    }
                    isAll={this.state.isAll}
                  />
                </div>
              ))}
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <Loader show={this.state.isLoad || this.state.isLoadPagination} />
        <PrintClass
          refs={
            this.state.isPrint && this.state.isLoad
              ? this.state.tabsIndex === 0
                ? this.refPrint
                : this.refPrintAllianz
              : []
          }
          title={"Qualité client"}
          fileName={"Qualité client"}
          onPrint={(print) => {
            this.setState({
              isPrint: print,
              isLoad: print ? this.state.isLoad + 1 : this.state.isLoad - 1,
            });
          }}
          width={1600}
        />
      </>
    );
  }
}
