import React from "react";
import { Card, BarChart } from "ui-kit-ck-consultant";

export default class Global extends React.Component {
  render() {
    if (!this.props.generalDataChart) {
      return null;
    }

    return (
      <Card className={this.props.className} title="Évolution">
        <BarChart
          height={350}
          digits={2}
          title={"Note de satisfaction moyenne annuelle / région"}
          labels={this.props.generalDataChart.years_labels}
          dataSet={[
            ...this.props.generalDataChart.years.map((element) => {
              return {
                type: "line",
                label: element.name,
                data: element.average,
                color: element.color,
                yAxisID: "A",
              };
            }),
            ...this.props.generalDataChart.years.map((element) => {
              return {
                type: "bar",
                label: element.name,
                data: element.count,
                color: element.color,
                yAxisID: "B",
              };
            }),
          ]}
          isLegend={true}
          yAxes={[
            {
              id: "A",
              ticks: {},
            },
            {
              id: "B",
              ticks: {},
            },
          ]}
        />
        <BarChart
          height={350}
          digits={2}
          title={"Note de satisfaction moyenne mensuelle / région"}
          labels={this.props.generalDataChart.months_labels}
          dataSet={[
            ...this.props.generalDataChart.months.map((element) => {
              return {
                type: "line",
                label: element.name,
                data: element.average,
                color: element.color,
                yAxisID: "A",
              };
            }),
            ...this.props.generalDataChart.months.map((element) => {
              return {
                type: "bar",
                label: element.name,
                data: element.count,
                color: element.color,
                yAxisID: "B",
              };
            }),
          ]}
          isLegend={true}
          yAxes={[
            {
              id: "A",
              ticks: { beginAtZero: false },
            },
            {
              id: "B",
              ticks: { beginAtZero: false },
            },
          ]}
        />
      </Card>
    );
  }
}
