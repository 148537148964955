import React, { Component } from "react";
import moment from "moment";
import { Tr, Td } from "ui-kit-ck-consultant";

import Input from "./Input";

import ValidateButtons from "./ValidateButtons";

import AuthContext from "../../../context/AuthContext";
import Select from "./Select";
export default class PlanningRow extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      currentPlanningId: -1,
    };
  }

  changeState(stateObject) {
    this.setState(stateObject);
  }

  getColorFromStatus = (status) => {
    if (status === 1) {
      return "bg-orange-light";
    } else if (status === 2) {
      return "bg-green-light";
    } else if (status === 3) {
      return "bg-red-light";
    } else {
      return "";
    }
  };

  render() {
    return (
      <>
        {this.props.data.map((el, idx) => {
          return (
            <Tr key={idx}>
              {this.props.tableHead.map((elemHead, idxHead) => {
                return elemHead.name === "action" ? (
                  <Td
                    key={idxHead}
                    className={this.getColorFromStatus(el.status)}
                  >
                    <ValidateButtons
                      updatePlannings={() => this.props.updatePlannings()}
                      className={"m-auto"}
                      planning={el}
                      currentPlanningId={this.state.currentPlanningId}
                      showInput={this.state.showInput}
                      setShowInput={this.changeState.bind(this)}
                    />
                  </Td>
                ) : (
                  <>
                    {(!this.state.showInput ||
                      (this.state.showInput &&
                        this.state.currentPlanningId !== el.id)) && (
                      <Td
                        key={idxHead}
                        className={this.getColorFromStatus(el.status)}
                      >
                        {["start", "end"].includes(elemHead.name)
                          ? moment(el[elemHead.name]).format("DD/MM/YYYY")
                          : el[elemHead.name]}
                      </Td>
                    )}
                    {this.state.showInput &&
                      this.state.currentPlanningId === el.id && (
                        <Td
                          key={el.id}
                          className={this.getColorFromStatus(el.status)}
                        >
                          {(elemHead.name === "insertDate" ||
                            elemHead.name === "userName" ||
                            elemHead.name === "managerName" ||
                            elemHead.name === "rhName") &&
                            el[elemHead.name]}
                          {elemHead.name === "start" && (
                            <Input
                              style={{
                                border: "none",
                                height: "36px",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                              }}
                              type="date"
                              value={el[elemHead.name]}
                              onChange={(newValue) => {
                                el[elemHead.name] = newValue;
                                this.props.updateDataList(
                                  idx,
                                  el[elemHead.name]
                                );
                              }}
                            />
                          )}
                          {elemHead.name === "end" && (
                            <Input
                              style={{
                                border: "none",
                                height: "36px",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                              }}
                              type="date"
                              value={el[elemHead.name]}
                              onChange={(newValue) => {
                                el[elemHead.name] = newValue;
                                this.props.updateDataList(
                                  idx,
                                  el[elemHead.name]
                                );
                              }}
                            />
                          )}

                          {(elemHead.name === "typeEnd" ||
                            elemHead.name === "typeStart") &&
                            el.type !== "Formation" && (
                              <Select
                                style={{
                                  border: "none",
                                  height: "36px",
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                                options={[
                                  {
                                    value: "à partir du matin",
                                    text: "à partir du matin",
                                  },
                                  {
                                    value: "à partir de l'aprés midi",
                                    text: "à partir de l'aprés midi",
                                  },
                                ]}
                                value={el[elemHead.name]}
                                onChange={(newValue) => {
                                  el[elemHead.name] = newValue;
                                  this.props.updateDataList(
                                    idx,
                                    el[elemHead.name]
                                  );
                                }}
                              />
                            )}
                          {elemHead.name === "type" && (
                            <>
                              {el[elemHead.name] !== "Formation" && (
                                <Select
                                  style={{
                                    border: "none",
                                    height: "36px",
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                  }}
                                  options={[
                                    {
                                      text: "Congés annuel",
                                      value: "Congés annuel",
                                    },
                                    { text: "Sans solde", value: "Sans solde" },
                                    {
                                      text: "Absence pour maladie",
                                      value: "Absence pour maladie",
                                    },
                                    { text: "Chomage", value: "Chomage" },
                                    {
                                      text: "En déplacement",
                                      value: "En déplacement",
                                    },
                                    {
                                      text: "Déplacement professionnel",
                                      value: "Déplacement professionnel",
                                    },
                                    { text: "Formation", value: "Formation" },
                                    { text: "RTT", value: "RTT" },
                                    {
                                      text: "Récupération",
                                      value: "Récupération",
                                    },
                                    {
                                      text: "Enfant malade",
                                      value: "Enfant malade",
                                    },
                                    {
                                      text: "Congés maternité",
                                      value: "Congés maternité",
                                    },
                                  ]}
                                  value={el[elemHead.name]}
                                  onChange={(newValue) => {
                                    el[elemHead.name] = newValue;
                                    this.props.updateDataList(
                                      idx,
                                      el[elemHead.name]
                                    );
                                  }}
                                />
                              )}
                              {el[elemHead.name] === "Formation" &&
                                el[elemHead.name]}
                            </>
                          )}
                        </Td>
                      )}
                  </>
                );
              })}
            </Tr>
          );
        })}
      </>
    );
  }
}
