import React, { Component } from "react";
import moment from "moment";
import { Row, Col } from "react-flexbox-grid";
import { Card, DoughnutChart } from "ui-kit-ck-consultant";
import { getNumberValue } from "../../utils/general";

export default class Train extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card className="mb-10 mb-20-xl" title="Apprentissage et prédiction">
        <Row>
          <Col xs={12} md={6} lg={4} className="d-flex">
            <div className="m-auto text-center">
              <DoughnutChart
                className="m-auto"
                number={parseInt(this.props.precision * 10000) / 100}
                text="Précision (%)"
              />
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="d-flex">
            <div className="mt-auto mb-auto">
              <h3 className="mt-0 bm-5">Critères</h3>
              {this.props.filters.map((element, idx) => (
                <p key={idx} className="m-0" style={{ fontSize: "12px" }}>
                  {element.name} : <b>{element.value}</b>
                </p>
              ))}
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="d-flex">
            <div className="mt-auto mb-auto">
              <p className="m-0" style={{ fontSize: "12px" }}>
                Date de prédiction : <b>{this.props.date}</b>
              </p>
              <p className="m-0" style={{ fontSize: "12px" }}>
                Heure de prédiction : <b>{this.props.time}</b>
              </p>
              {["mse", "rmse", "mae", "count"].map((element, idx) => (
                <p key={idx} className="m-0" style={{ fontSize: "12px" }}>
                  {element.toUpperCase()} :{" "}
                  <b>{getNumberValue(this.props.details[element], 0)}</b>
                </p>
              ))}
              <p className="m-0" style={{ fontSize: "12px" }}>
                Clôture {">="}{" "}
                <b>
                  {moment(this.props.details.start_date)
                    .add(1, "days")
                    .format("DD/MM/YYYY")}
                </b>
              </p>
              <p className="m-0" style={{ fontSize: "12px" }}>
                Clôture {"<="}{" "}
                <b>
                  {moment(this.props.details.end_date).format("DD/MM/YYYY")}
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}
