import React, { Component } from "react";
import moment from "moment";
import { FormInput, FormSelect, Button } from "ui-kit-ck-consultant";

import DropZone from ".//DropZone";

import AuthContext from "../../../context/AuthContext";

export default class PlanningForm extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      dateNow: new Date().toISOString().split("T")[0],
      inputErrors: {},
    };
  }

  componentDidUpdate(prevProps) {
    const dateStart = moment(this.props.dateStart);
    const dateEnd = moment(this.props.dateEnd);
    if (
      this.props.dateStart !== prevProps.dateStart ||
      this.props.dateEnd !== prevProps.dateEnd
    ) {
      if (dateEnd.isBefore(dateStart)) {
        this.props.onError(true);
      } else if (this.props.error) {
        this.props.onError(false);
      }
    }
  }
  render() {
    return (
      <>
        <FormSelect
          required
          className={"mb-10"}
          title={"Type"}
          init="Veuillez sélectionner un type"
          name="type"
          ignore={true}
          value={this.props.type}
          options={[
            { text: "Congés annuel", value: "Congés annuel" },
            { text: "Sans solde", value: "Sans solde" },
            { text: "Absence pour maladie", value: "Absence pour maladie" },
            { text: "Chomage", value: "Chomage" },
            { text: "En déplacement", value: "En déplacement" },
            {
              text: "Déplacement professionnel",
              value: "Déplacement professionnel",
            },
            { text: "Formation", value: "Formation" },
            { text: "RTT", value: "RTT" },
            { text: "Récupération", value: "Récupération" },
            { text: "Enfant malade", value: "Enfant malade" },
            { text: "Congés maternité", value: "Congés maternité" },
          ]}
          onChange={this.props.handleInputChange}
        />
        {this.props.type.length > 0 && (
          <>
            {[1, 2, 3, 4].includes(this.context.role) && (
              <div className="mb-10">
                <span
                  style={{
                    display: "inline-block",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "var(--black)",
                    marginBottom: "8px",
                  }}
                >
                  Utilisateur
                </span>
                <input
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "solid 2px var(--gray)",
                    borderRadius: "8px",
                    color: "var(--black)",
                    fontSize: "14px",
                    padding: "0 20px",
                    transition: "0.6s",
                    opacity: "1",
                  }}
                  list="usersList"
                  name="user"
                  value={this.props.user}
                  onChange={this.props.handleInputChange}
                />
                <datalist id="usersList">
                  {this.props.userOptions.map((user, idx) => (
                    <option key={idx} value={`${user.value} - ${user.text}`}>
                      {user.text}
                    </option>
                  ))}
                </datalist>
              </div>
            )}
            <FormInput
              required
              styleInput={
                this.state.inputErrors.dateStart || this.props.error
                  ? { border: "1px solid red" }
                  : null
              }
              className={"mb-10"}
              type="date"
              title={"Date de départ"}
              min={`${moment().format("YYYY")}-01-01`}
              name="dateStart"
              value={this.props.dateStart}
              onChange={this.props.handleInputChange}
            />
            <FormInput
              required
              styleInput={
                this.state.inputErrors.dateEnd || this.props.error
                  ? { border: "1px solid red" }
                  : null
              }
              className={"mb-10"}
              type="date"
              title={"Date de retour"}
              min={this.props.dateStart}
              name="dateEnd"
              value={this.props.dateEnd}
              onChange={this.props.handleInputChange}
            />
            {this.props.type === "Formation" && (
              <>
                <FormSelect
                  required
                  styleInput={
                    this.state.inputErrors.organization
                      ? { border: "1px solid red" }
                      : null
                  }
                  className={"mb-10"}
                  title={"Organisme de formation"}
                  init="Veuillez sélectionner un organisme"
                  name="organization"
                  ignore={true}
                  value={this.props.organization}
                  options={this.props.organizationOptions}
                  onChange={this.props.handleInputChange}
                />
                {this.props.organizationTypeOptions.length > 0 ? (
                  <FormSelect
                    required
                    styleInput={
                      this.state.inputErrors.typeOrganization
                        ? { border: "1px solid red" }
                        : null
                    }
                    className={"mb-10"}
                    title={"Intitulé de la formation"}
                    init="Veuillez sélectionner un intitulé de formation"
                    name="typeOrganization"
                    ignore={true}
                    value={this.props.typeOrganization}
                    options={this.props.organizationTypeOptions}
                    onChange={this.props.handleInputChange}
                  />
                ) : null}
                {this.props.organization === "4" ? (
                  <>
                    <FormInput
                      required
                      styleInput={
                        this.state.inputErrors.nameOrganization
                          ? { border: "1px solid red" }
                          : null
                      }
                      className={"mb-10"}
                      type="text"
                      title={"Nom de l'organisme"}
                      name="nameOrganization"
                      value={this.props.nameOrganization}
                      onChange={this.props.handleInputChange}
                    />
                    <FormInput
                      required
                      styleInput={
                        this.state.inputErrors.nameFormation
                          ? { border: "1px solid red" }
                          : null
                      }
                      className={"mb-10"}
                      type="text"
                      title={"Nom de la formation"}
                      name="nameFormation"
                      value={this.props.nameFormation}
                      onChange={this.props.handleInputChange}
                    />
                  </>
                ) : null}
                <FormInput
                  required
                  styleInput={
                    this.state.inputErrors.address
                      ? { border: "1px solid red" }
                      : null
                  }
                  className={"mb-10"}
                  type="text"
                  title={"Adresse de formation"}
                  name="address"
                  value={this.props.address}
                  onChange={this.props.handleInputChange}
                />
                <FormInput
                  required
                  styleInput={
                    this.state.inputErrors.zipCode
                      ? { border: "1px solid red" }
                      : null
                  }
                  className={"mb-10"}
                  type="text"
                  title={"Code postal de la formation"}
                  name="zipCode"
                  value={this.props.zipCode}
                  onChange={this.props.handleInputChange}
                />
                <FormInput
                  required
                  styleInput={
                    this.state.inputErrors.city
                      ? { border: "1px solid red" }
                      : null
                  }
                  className={"mb-10"}
                  type="text"
                  title={"Ville de la formation"}
                  name="city"
                  value={this.props.city}
                  onChange={this.props.handleInputChange}
                />
              </>
            )}
            {this.props.type !== "Formation" && (
              <>
                <FormSelect
                  required
                  styleInput={
                    this.state.inputErrors.typeStart
                      ? { border: "1px solid red" }
                      : null
                  }
                  className={"mb-10"}
                  title={"Type de départ"}
                  init="Veuillez sélectionner un type de départ"
                  name="typeStart"
                  ignore={true}
                  value={this.props.typeStart}
                  options={[
                    { value: "à partir du matin", text: "à partir du matin" },
                    {
                      value: "à partir de l'aprés midi",
                      text: "à partir de l'aprés midi",
                    },
                  ]}
                  onChange={this.props.handleInputChange}
                />
                <FormSelect
                  required
                  styleInput={
                    this.state.inputErrors.typeEnd
                      ? { border: "1px solid red" }
                      : null
                  }
                  className={"mb-10"}
                  title={"Type de retour"}
                  init="Veuillez sélectionner un type de retour"
                  name="typeEnd"
                  ignore={true}
                  value={this.props.typeEnd}
                  options={[
                    { value: "à partir du matin", text: "à partir du matin" },
                    {
                      value: "à partir de l'aprés midi",
                      text: "à partir de l'aprés midi",
                    },
                  ]}
                  onChange={this.props.handleInputChange}
                />
                <FormInput
                  className={"mb-10"}
                  type="text"
                  title="Détails congés (facultatif)"
                  name="comment"
                  value={this.props.comment}
                  onChange={this.props.handleInputChange}
                />
                <DropZone getFile={this.props.getFile} />
              </>
            )}
            <Button
              disabled={this.props.error}
              className={"mb-10"}
              text={"Valider"}
              onClick={() => {
                const {
                  type,
                  dateStart,
                  dateEnd,
                  comment,
                  user,
                  typeStart,
                  typeEnd,
                  address,
                  zipCode,
                  city,
                  organization,
                  typeOrganization,
                  nameOrganization,
                  nameFormation,
                  file,
                } = this.props;

                if (
                  moment(dateStart).format("YYYY") >= moment().format("YYYY")
                ) {
                  const data =
                    this.props.type !== "Formation"
                      ? {
                          type,
                          dateStart,
                          dateEnd,
                          comment,
                          file,
                          userId: user.length > 0 ? user : this.context.id,
                          typeStart,
                          typeEnd,
                        }
                      : {
                          type,
                          dateStart,
                          dateEnd,
                          userId: user.length > 0 ? user : this.context.id,
                          address,
                          organization,
                          typeOrganization,
                          nameOrganization,
                          nameFormation,
                          zipCode,
                          city,
                        };

                  for (const key in data) {
                    if (!data[key] && key !== "comment" && key !== "file") {
                      this.setState((prevState) => ({
                        inputErrors: {
                          ...prevState.inputErrors,
                          [key]: true,
                        },
                      }));
                    } else if (key in this.state.inputErrors) {
                      delete this.state.inputErrors[key];
                    }
                  }
                  this.props.handleValidClick(data);
                } else {
                  alert("Veuillez vérifier la date de départ");
                }
              }}
            />
          </>
        )}
      </>
    );
  }
}
