import React, { Component, Fragment } from "react";
import {
  Loader,
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Button,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";

import ShowOptionsNGRA from "../../components/general/ShowOptionsNGRA-v2";

import axiosConfig from "../../utils/axiosConfig";

import Train from "../../components/precisia/Train";
import Data from "../../components/precisia/Data";
import Chart from "../../components/precisia/Chart";
import Criteria from "../../components/precisia/Criteria";

import AuthContext from "../../context/AuthContext";
import { getCurrencyValue, getNumberValue } from "../../utils/general";

export default class Precisia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      // ShowOption state
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      year: moment().format("YYYY"),
      user: 0,
      company: 0,
      // PrécisIA data
      precisia: "",
      optionsPrecisia: [],
      data: [{}, {}, {}, {}],
      dataRegion: [[], [], [], []],
      dataAgency: [[], [], [], []],
      dataExpert: [[], [], [], []],
      // Tabs
      tabsMenu: [
        {
          name: "Réparable",
          filters: [
            {
              name: "Nature expertise",
              value: "Normale, SUIVI V.G.E.",
            },
            { name: "Genre", value: "VP, CTTE" },
            {
              name: "Nature évènement",
              value: "Circulation, Stationnement, Accident en chaine",
            },
            { name: "Puissance fiscale", value: "1 à 15" },
            { name: "Total rapport HT", value: "20 € à 5 000 €" },
            {
              name: "Carburant",
              value: "ES, GO, EL, EH, EE, EG, GH, GL, FE, GP",
            },
            { name: "Âge", value: "< 30 ans" },
          ],
          2024: {
            precision: 0.8698278569590965,
            mse: 174922.27247903892,
            rmse: 418.237100792169,
            mae: 294.4155303751798,
            folder: "R",
            year: "2024",
            count: 67150,
            start_date: "2023-06-30",
            end_date: "2023-12-31",
          },
          2025: {
            precision: 0.8769368900301058,
            mse: 168492.60682881175,
            rmse: 410.47850958218476,
            mae: 287.0239269825825,
            folder: "R",
            year: "2025",
            count: 259060,
            start_date: "2022-12-31",
            end_date: "2024-12-31",
          },
        },
        {
          name: "EAD",
          filters: [
            { name: "Nature expertise", value: "Téléchiffrage" },
            { name: "Genre", value: "VP, CTTE" },
            {
              name: "Nature évènement",
              value: "Circulation, Stationnement, Accident en chaine",
            },
            { name: "Puissance fiscale", value: "1 à 15" },
            { name: "Total rapport HT", value: "20 € à 5 000 €" },
            {
              name: "Carburant",
              value: "ES, GO, EL, EH, EE, EG, GH, GL, FE, GP",
            },
            { name: "Âge", value: "< 30 ans" },
          ],
          2024: {
            precision: 0.8396468070136909,
            mse: 95224.47573144591,
            rmse: 308.5846330124783,
            mae: 209.9850123394939,
            folder: "EAD",
            year: "2024",
            count: 127208,
            start_date: "2021-12-31",
            end_date: "2023-12-31",
          },
          2025: {
            precision: 0.8374541804380978,
            mse: 112322.3121051298,
            rmse: 335.14521047619013,
            mae: 227.20199630446226,
            folder: "EAD",
            year: "2025",
            count: 72465,
            start_date: "2023-12-31",
            end_date: "2024-12-31",
          },
        },
        {
          name: "VRADE",
          filters: [
            {
              name: "Nature expertise",
              value:
                "Véh. économmiquement irréparable (VEI), Différence des valeurs",
            },
            { name: "Genre", value: "VP, CTTE" },
            {
              name: "Nature évènement",
              value: "Circulation, Stationnement, Accident en chaine",
            },
            { name: "Puissance fiscale", value: "1 à 15" },
            { name: "VRADE HT", value: "50 € à 10 000 €" },
            {
              name: "Carburant",
              value: "ES, GO, EL, EH, EE, EG, GH, GL, FE, GP",
            },
            { name: "Âge", value: "< 30 ans" },
            { name: "Apprentissage", value: "2022-2024" },
            { name: "Dossier", value: "67 193" },
          ],
          2024: {
            precision: 0.8587965649235965,
            mse: 829796.7599324855,
            rmse: 910.9318086072554,
            mae: 653.8947050025987,
            folder: "NR",
            year: "2024",
            count: 19683,
            start_date: "2022-12-31",
            end_date: "2023-12-31",
          },
          2025: {
            precision: 0.8583259470601238,
            mse: 832562.395046565,
            rmse: 912.4485711789815,
            mae: 652.1369700446699,
            folder: "NR",
            year: "2025",
            count: 19683,
            start_date: "2022-12-31",
            end_date: "2023-12-31",
          },
        },
        {
          name: "GES Réparable",
          filters: [
            {
              name: "Nature expertise",
              value: "Normale, SUIVI V.G.E.",
            },
            { name: "Genre", value: "VP, CTTE" },
            {
              name: "Nature évènement",
              value: "Circulation, Stationnement, Accident en chaine",
            },
            { name: "Puissance fiscale", value: "1 à 15" },
            { name: "Total rapport HT", value: "20 € à 5 000 €" },
            {
              name: "Carburant",
              value: "ES, GO, EL, EH, EE, EG, GH, GL, FE, GP",
            },
            { name: "Âge", value: "< 30 ans" },
            { name: "Apprentissage", value: "" },
            { name: "Dossier", value: "" },
          ],
          2024: {},
          2025: {},
        },
      ],
      tabsId: 0,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.tabsId === 0) {
          this.getDataPrecisIA(1);
        } else if (this.state.tabsId === 1) {
          this.getDataPrecisIA(2);
        } else if (this.state.tabsId === 2) {
          this.getDataPrecisIA(3);
        }
      }
    );
  };

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        year: data.year,
        user: data.user,
        company: data.company,
      },
      () => {
        this.initOptions();
      }
    );
  };

  initOptions = () => {
    if (this.state.tabsId === 0) {
      this.getOptionsPrecisIA(1);
    } else if (this.state.tabsId === 1) {
      this.getOptionsPrecisIA(2);
    } else if (this.state.tabsId === 2) {
      this.getOptionsPrecisIA(3);
    } else if (this.state.tabsId === 3) {
      this.getOptionsPrecisIA(4);
    }
  };

  getOptionsPrecisIA = (type) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("precisia/get-options", {
        data: {
          year: this.state.year,
          type,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState(
            {
              isLoad: false,
              precisia: res.data.data[0].value,
              optionsPrecisia: [
                {
                  value: "",
                  text: "Veuillez sélectionner une génération",
                  disabled: true,
                },
                ...res.data.data,
              ],
            },
            () => {
              this.getDataPrecisIA(type, this.state.tabsId);
            }
          );
        } else {
          this.setState({ isLoad: false, optionsPrecisia: [] });
        }
      });
    });
  };

  getDataPrecisIA = (type) => {
    if (this.state.precisia) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("precisia/get-data", {
          data: {
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
            user: this.state.user,
            company: this.state.company,
            dateScan: this.state.precisia,
            type,
          },
        }).then((res) => {
          let data = this.state.data;
          let dataRegion = this.state.dataRegion;
          let dataAgency = this.state.dataAgency;
          let dataExpert = this.state.dataExpert;

          if (res.data.success) {
            data[this.state.tabsId] = res.data.data;
            dataRegion[this.state.tabsId] = res.data.dataRegion;
            dataAgency[this.state.tabsId] = res.data.dataAgency;
            dataExpert[this.state.tabsId] = res.data.dataExpert;
          } else {
            data[this.state.tabsId] = {};
            dataRegion[this.state.tabsId] = [];
            dataAgency[this.state.tabsId] = [];
            dataExpert[this.state.tabsId] = [];
          }

          this.setState({
            data,
            dataRegion,
            dataAgency,
            dataExpert,
            isLoad: false,
          });
        });
      });
    }
  };

  getDataCSV() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("precisia/get-data-csv", {
        data: {
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          user: this.state.user,
          company: this.state.company,
          dateScan: this.state.precisia,
          type: this.state.tabsId + 1,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({ isLoad: false }, () => {
            const header = [
              "reference",
              "agencyName",
              "regionName",
              "expertName",
              "totalReportDutyFree",
              "VRADEDutyFree",
              "predict",
              "licensePlate",
              "dateClosing",
              "dateAccident",
              "dateVehicle",
            ];

            let texts = "";

            header.forEach((column) => {
              texts += `${column};`;
            });
            texts += "\n";
            res.data.data.forEach((folder) => {
              texts += `${folder.reference};${folder.agencyName};${
                folder.regionName
              };${folder.expertName};${
                this.props.format === "€"
                  ? getCurrencyValue(folder.totalReportDutyFree)
                  : getNumberValue(folder.totalReportDutyFree)
              };${
                this.props.format === "€"
                  ? getCurrencyValue(folder.VRADEDutyFree)
                  : getNumberValue(folder.VRADEDutyFree)
              };${
                this.props.format === "€"
                  ? getCurrencyValue(folder.predict)
                  : getNumberValue(folder.predict)
              };${folder.licensePlate};${moment(folder.dateClosing).format(
                "DD/MM/YYYY"
              )};${moment(folder.dateAccident).format("DD/MM/YYYY")};${moment(
                folder.dateVehicle
              ).format("DD/MM/YYYY")};\n`;
            });

            let blob = new Blob(["\ufeff", texts], { type: "text/csv" });
            let link = document.createElement("a");

            link.href = URL.createObjectURL(blob);
            link.download = `extract_precisia_${moment().format(
              "YYYY_MM_DD_HH_mm"
            )}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  }

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="PrécisIA" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isYear={true}
            isUser={true}
            isCompany={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            isAll={true}
            notAllUsersType={true}
            userType={[2, 3]}
            minYear={2021}
            maxYear={moment().format("YYYY")}
            others={
              <>
                {this.state.year ? (
                  <Button
                    text="Télécharger les données"
                    onClick={() => {
                      this.getDataCSV();
                    }}
                  />
                ) : null}
              </>
            }
          />
          <TabsCustom>
            <TabsCustomHeader>
              {this.state.tabsMenu.map((element, key) => (
                <TabsCustomMenu
                  disabled={this.props.isLoad}
                  key={key}
                  title={element.name}
                  active={this.state.tabsId === key}
                  onClick={() => {
                    this.setState({ tabsId: key }, this.initOptions);
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              <TabsCustomItem active={true}>
                {this.state.tabsMenu.map((element, key) => {
                  if (this.state.tabsId === key) {
                    return (
                      <Fragment key={key}>
                        <Train
                          format={this.state.tabsId === 3 ? "kg CO2e" : "€"}
                          precision={
                            (element[this.state.year] || {} || {}).precision
                          }
                          filters={element.filters}
                          details={element[this.state.year] || {}}
                          date={moment(this.state.precisia).format(
                            "DD/MM/YYYY"
                          )}
                          time={moment(this.state.precisia).format("HH:mm:ss")}
                        />
                        <Chart
                          format={this.state.tabsId === 3 ? "kg CO2e" : "€"}
                          data={this.state.data[key]}
                          dataRegion={this.state.dataRegion[key]}
                          dataAgency={this.state.dataAgency[key]}
                          dataExpert={this.state.dataExpert[key]}
                        />
                        <Data
                          format={this.state.tabsId === 3 ? "kg CO2e" : "€"}
                          data={this.state.data[key]}
                          dataRegion={this.state.dataRegion[key]}
                          dataAgency={this.state.dataAgency[key]}
                          dataExpert={this.state.dataExpert[key]}
                        />
                        <Criteria
                          format={this.state.tabsId === 3 ? "kg CO2e" : "€"}
                          tabsId={this.state.tabsId}
                          data={this.state.data[key]}
                        />
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              </TabsCustomItem>
            </TabsCustomBody>
          </TabsCustom>
        </FadeIn>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
