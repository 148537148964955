import React, { Component } from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
  FormRadio,
  Button,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import GeneralData from "../../../components/company/performance/GeneralData";
import PrintClass from "../../../components/general/Print";
import CardCompany from "../../../components/company/performance/card-company/CardCompany";

import "../../../style/companyPerformance.css";

import axiosConfig from "../../../utils/axiosConfig";
import { alertError } from "../../../utils/errorMessage";

import AuthContext from "../../../context/AuthContext";

export default class CompanyPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Loader state
      isLoad: false,
      // ShowOption state
      year: 0,
      network: 0,
      group: 0,
      region: 0,
      agency: [],
      company: 0,
      user: 0,
      filter: "1",
      kind: [],
      // Data state
      performanceAgency: {},
      data: null,
      dataChart: null,
      dataOpenDescriptifState: [],
      dataOpenMarketSearch: [],
      // Tabs state
      tabsData: [],
      tabsMenu: [],
      tabsIndex: 0,
      companyLogo: null,
      tabsIndexGeneral: 0,
    };

    this.refPrintGlobal = React.createRef();
  }

  static contextType = AuthContext;

  handleRadioChange = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.getGeneralData
    );
  };

  handleDataChange = (data) => {
    this.setState(
      {
        year: data.year,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: [...data.agencyMultiples],
        company: data.company,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        kind: data.kind,
      },
      () => {
        if (
          [1, 2, 3, 5, 7, 8, 9, 10, 11, 13, 16, 20, 29].includes(
            parseInt(this.state.company)
          )
        ) {
          this.setState(
            {
              tabsMenu: ["Général", "Spécifique"],
              tabsData: ["Data Général", "Data Spécifique"],
            },
            () => {
              if (this.state.tabsIndex === 0) {
                this.getGeneralData();
              }
            }
          );
        } else {
          this.setState(
            {
              tabsMenu: ["Général"],
              tabsData: ["Data Général"],
              tabsIndex: 0,
            },
            () => {
              if (this.state.tabsIndex === 0) {
                this.getGeneralData();
              }
            }
          );
        }
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.company !== this.state.company && this.state.company !== 0) {
      this.getCompanyLogo();
    }
  }

  getCompanyLogo = () => {
    axiosConfig("/company-performance/get-company-logo", {
      data: {
        id: this.state.company,
      },
    }).then((res, err) => {
      if (res && res.data.logo) {
        const companyLogo = res.data.logo;
        this.setState({ companyLogo });
      } else {
        this.setState({ companyLogo: null });
      }
    });
  };

  updatePerformanceAgency = () => {
    axiosConfig("/company-performance/update-performance-agency", {
      data: {
        agency: this.state.agency,
        performanceAgency: this.state.performanceAgency,
      },
    }).then((res, err) => {});
  };

  getGeneralData = () => {
    this.setState({ isLoad: true, data: null, dataChart: null }, () => {
      axiosConfig("/company-performance/get-general-data", {
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          company: this.state.company,
          type: this.state.tabsIndex,
          user: this.state.user,
          userType: this.context.type === 5 ? [5] : [2, 3],
          filter: this.state.filter,
          kind: this.state.kind,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              performanceAgency: res.data.performanceAgency,
              data: res.data.dataGeneral,
              dataChart: res.data.dataChart,
              dataOpenDescriptifState: res.data.dataOpenDescriptifState,
              dataOpenMarketSearch: res.data.dataOpenMarketSearch,
              isLoad: false,
            });
          } else {
            this.setState({
              isLoad: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoad: false });
          alertError(err);
        });
    });
  };

  render() {
    return (
      <>
        <FadeIn>
          <div ref={this.refPrintGlobal}>
            <Nav
              className="mb-10 mb-20-xl nowrap"
              title="Performance compagnie"
            >
              {this.state.companyLogo ? (
                <img
                  className="imageCompany"
                  src={this.state.companyLogo}
                  alt="logo-company"
                />
              ) : null}
            </Nav>
            <ShowOptionsNGRA
              isKind
              defaultKind={["VP", "CTTE"]}
              isChange={true}
              className="mb-10 mb-20-xl"
              onDataChange={this.handleDataChange}
              isCompany={true}
              isUser={true}
              isDateStart={true}
              isDateEnd={true}
              isAll={true}
              isAgencyMultiples={true}
              visibilityRole={this.props.visibilityRole}
              visibilityType={this.props.visibilityType}
              preloadRole={this.props.preloadRole}
              preloadType={this.props.preloadType}
              limitUserType={this.props.limitUserType}
              limitUserRole={this.props.limitUserRole}
              allUserRole={this.props.allUserRole}
              userType={this.context.type === 5 ? [5] : [2, 3]}
              others={
                <>
                  {this.state.company === 3 ? (
                    <>
                      <FormRadio
                        className="mr-10"
                        name="filter"
                        value={"1"}
                        checked={this.state.filter === "1"}
                        onChange={this.handleRadioChange}
                        text="R 21 - 4000 / NR : 100 - 12 000"
                      />
                      <FormRadio
                        className="mr-10"
                        name="filter"
                        value={"2"}
                        checked={this.state.filter === "2"}
                        onChange={this.handleRadioChange}
                        text="R 21 - 35000 / NR : 0 - 35000"
                      />
                    </>
                  ) : null}
                  {this.state.company === 8 ? (
                    <>
                      <FormRadio
                        className="mr-10"
                        name="filter"
                        value={"1"}
                        checked={this.state.filter === "1"}
                        onChange={this.handleRadioChange}
                        text="NR < 10 ans - 1 000 à 8 500 € HT"
                      />
                      <FormRadio
                        className="mr-10"
                        name="filter"
                        value={"2"}
                        checked={this.state.filter === "2"}
                        onChange={this.handleRadioChange}
                        text="NR >= 10 ans - 0 à 5 000 € HT"
                      />
                    </>
                  ) : null}
                  <Button
                    disabled={!this.state.data}
                    className="w-auto"
                    text="Télécharger CSV"
                    onClick={() => {
                      (() => {
                        const element = document.createElement("a");
                        // eslint-disable-next-line no-undef
                        const file = new Blob([this.state.data.dataR_CSV], {
                          type: "text/csv;charset=utf-8",
                        });
                        element.href = URL.createObjectURL(file);
                        element.download = "extract_R.csv";
                        document.body.appendChild(element);
                        element.click();
                      })();

                      (() => {
                        const element = document.createElement("a");
                        // eslint-disable-next-line no-undef
                        const file = new Blob([this.state.data.dataNR_CSV], {
                          type: "text/csv;charset=utf-8",
                        });
                        element.href = URL.createObjectURL(file);
                        element.download = "extract_NR.csv";
                        document.body.appendChild(element);
                        element.click();
                      })();
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </>
              }
            />
            {this.state.tabsData.length > 0 ? (
              <TabsCustom>
                <TabsCustomHeader>
                  {this.state.tabsMenu.map((element, idx) => (
                    <TabsCustomMenu
                      key={idx}
                      title={element}
                      active={this.state.tabsIndex === idx}
                      disabled={!this.state.tabsData[idx]}
                      onClick={(e) => {
                        if (this.state.tabsIndex !== idx) {
                          this.state.tabsData[idx] &&
                            this.setState({ tabsIndex: idx }, () => {
                              if (this.state.tabsIndex === 0) {
                                this.getGeneralData();
                              }
                            });
                        }
                      }}
                    />
                  ))}
                </TabsCustomHeader>
                <TabsCustomBody noCard>
                  {this.state.tabsMenu.map((element, idx) => (
                    <TabsCustomItem
                      key={idx}
                      active={this.state.tabsIndex === idx}
                    >
                      {idx === 0 ? (
                        this.state.data && this.state.dataChart ? (
                          <GeneralData
                            leaveUpdatePerformanceAgency={() =>
                              this.updatePerformanceAgency()
                            }
                            updatePerformanceAgency={(name, value) => {
                              let tmpData = JSON.parse(
                                JSON.stringify(this.state.performanceAgency)
                              );

                              tmpData[name] = value;

                              this.setState({
                                performanceAgency: tmpData,
                              });
                            }}
                            performanceAgency={this.state.performanceAgency}
                            network={this.state.network}
                            group={this.state.group}
                            region={this.state.region}
                            agency={this.state.agency}
                            user={this.state.user}
                            company={this.state.company}
                            data={this.state.data}
                            dataChart={this.state.dataChart}
                            dataOpenDescriptifState={
                              this.state.dataOpenDescriptifState
                            }
                            dataOpenMarketSearch={
                              this.state.dataOpenMarketSearch
                            }
                            isPrint={this.state.isPrint}
                            tabsIndex={this.state.tabsIndexGeneral}
                            onChangeTab={(idx) =>
                              this.setState({ tabsIndexGeneral: idx })
                            }
                            onLabelsLoaded={(isLoad) =>
                              this.setState({ isLoad })
                            }
                          />
                        ) : null
                      ) : (
                        <>
                          {this.state.tabsIndex === 1 ? (
                            <CardCompany
                              company={this.state.company}
                              data={{
                                network: this.state.network,
                                group: this.state.group,
                                region: this.state.region,
                                agency: this.state.agency,
                                user: this.state.user,
                                company: this.state.company,
                                dateStart: this.state.dateStart,
                                dateEnd: this.state.dateEnd,
                                filter: this.state.filter,
                              }}
                              year={Number(this.state.year)}
                              isPrint={this.state.isPrint}
                            />
                          ) : null}
                        </>
                      )}
                    </TabsCustomItem>
                  ))}
                </TabsCustomBody>
              </TabsCustom>
            ) : null}
          </div>
        </FadeIn>
        <Loader show={this.state.isLoad} />
        <PrintClass
          refs={
            this.state.isPrint && this.state.isLoad ? [this.refPrintGlobal] : []
          }
          title={"Performances Compagnie"}
          fileName={"Performances Compagnie"}
          onPrint={(print) => this.setState({ isPrint: print, isLoad: print })}
          width={1000}
        />
      </>
    );
  }
}
