import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
} from "ui-kit-ck-consultant";

import {
  getPercentageValue,
} from "../../../../utils/general";
import axiosConfig from "../../../../utils/axiosConfig";

export default class CardALLIANZ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      // Tabs
      tabsMenu: ["Standard", "Évènement climatiques", "RC-PJ", "BONUS"],
      tabsIndex: 0,
      isLoad: false,
    };
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 1
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/allianzOM", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res, err) => {
        if (res.data.success) {
          this.setState({
            data: res.data.data,
            isLoad: false,
          });
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => alert("Une erreur est survenue")
          );
        }
      });
    });
  };

  getColor = (value, isReverse) => {
    if ((value > 0 && !isReverse) || (value < 0 && isReverse)) {
      return "bg-green-light green";
    } else {
      return "bg-red-light red";
    }
  };

  render() {
    return (
      <>
       <TabsCustom>
            <TabsCustomHeader>
              {this.state.tabsMenu.map((menu, index) => (
                <TabsCustomMenu
                  key={index}
                  title={menu}
                  active={this.state.tabsIndex === index}
                  onClick={() => this.setState({ tabsIndex: index })}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              {!this.state.data
                ? null
                : this.state.data.map((element, idx) => (
                    <TabsCustomItem
                      key={idx}
                      active={this.state.tabsIndex === idx}
                    >
                      <Card title={this.state.tabsMenu[idx]}>
                        <Table isCSV>
                          <Thead>
                            <Tr>
                              <Th></Th>
                              <Th className="nowrap">N-1</Th>
                              <Th className="nowrap">N</Th>
                              <Th className="nowrap">N/N-1</Th>
                              <Th>Janv.</Th>
                              <Th>Fév.</Th>
                              <Th>Mars</Th>
                              <Th>Avril</Th>
                              <Th>Mai</Th>
                              <Th>Juin</Th>
                              <Th>Juil.</Th>
                              <Th>Août</Th>
                              <Th>Sept.</Th>
                              <Th>Oct.</Th>
                              <Th>Nov.</Th>
                              <Th>Déc.</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {element.map((_element, _idx) => (
                              <Tr key={_idx}>
                                <Th>
                                  {_element.data[0] === "NaN"
                                    ? ""
                                    : _element.data[0]}
                                </Th>
                                <Td>
                                  {_element.data[2] === "NaN"
                                    ? ""
                                    : _element.data[2]}
                                </Td>
                                <Td>
                                  {_element.data[1] === "NaN"
                                    ? ""
                                    : _element.data[1]}
                                </Td>
                                <Td
                                  className={this.getColor(
                                    _element.data[15],
                                    _element.isReverse
                                  )}
                                >
                                  {_element.data[15] === "NaN"
                                    ? ""
                                    : getPercentageValue(_element.data[15])}
                                </Td>
                                <Td>
                                  {_element.data[3] === "NaN"
                                    ? ""
                                    : _element.data[3]}
                                </Td>
                                <Td>
                                  {_element.data[4] === "NaN"
                                    ? ""
                                    : _element.data[4]}
                                </Td>
                                <Td>
                                  {_element.data[5] === "NaN"
                                    ? ""
                                    : _element.data[5]}
                                </Td>
                                <Td>
                                  {_element.data[6] === "NaN"
                                    ? ""
                                    : _element.data[6]}
                                </Td>
                                <Td>
                                  {_element.data[7] === "NaN"
                                    ? ""
                                    : _element.data[7]}
                                </Td>
                                <Td>
                                  {_element.data[8] === "NaN"
                                    ? ""
                                    : _element.data[8]}
                                </Td>
                                <Td>
                                  {_element.data[9] === "NaN"
                                    ? ""
                                    : _element.data[9]}
                                </Td>
                                <Td>
                                  {_element.data[10] === "NaN"
                                    ? ""
                                    : _element.data[10]}
                                </Td>
                                <Td>
                                  {_element.data[11] === "NaN"
                                    ? ""
                                    : _element.data[11]}
                                </Td>
                                <Td>
                                  {_element.data[12] === "NaN"
                                    ? ""
                                    : _element.data[12]}
                                </Td>
                                <Td>
                                  {_element.data[13] === "NaN"
                                    ? ""
                                    : _element.data[13]}
                                </Td>
                                <Td>
                                  {_element.data[14] === "NaN"
                                    ? ""
                                    : _element.data[14]}
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Card>
                    </TabsCustomItem>
                  ))}
            </TabsCustomBody>
          </TabsCustom>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
