import React, { Component } from "react";
import CardACM from "./ACM/CardACM";
import CardAGPM from "./CardAGPM";
import CardALLIANZ from "./CardALLIANZ";
import CardABEILLE from "./CardABEILLE";
import CardAXA from "./CardAXA";
import CardCOVEA from "./CardCOVEA";
import CardGENERALI from "./CardGENERALI";
import CardGroupamaGan from "./CardGroupamaGan";
import CardMACIF from "./CardMACIF";
import CardMACSF from "./CardMACSF";
import CardMAIF from "./CardMAIF";
import CardMATMUT from "./CardMATMUT";
import CardMPOITIER from "./CardMPOITIER";
import CardAllianzOM from "./CardAllianzOM";

export class CardCompany extends Component {
  render() {
    const getComponent = () => {
      if (parseInt(this.props.company) === 1) {
        return (
          <CardALLIANZ
            key={this.props.company}
            data={parseInt(this.props.company) === 1 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 2) {
        return (
          <CardCOVEA
            key={this.props.company}
            data={parseInt(this.props.company) === 2 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 3) {
        return (
          <CardMACIF
            key={this.props.company}
            data={parseInt(this.props.company) === 3 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 5) {
        return (
          <CardACM
            key={this.props.company}
            data={parseInt(this.props.company) === 5 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 6) {
        return (
          <CardMACSF
            key={this.props.company}
            data={parseInt(this.props.company) === 6 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 7) {
        return (
          <CardMPOITIER
            key={this.props.company}
            data={parseInt(this.props.company) === 7 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 8) {
        return (
          <CardMAIF
            key={this.props.company}
            data={parseInt(this.props.company) === 8 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 9) {
        return (
          <CardMATMUT
            key={this.props.company}
            data={parseInt(this.props.company) === 9 ? this.props.data : null}
          />
        );
      } else if (
        parseInt(this.props.company) === 10 ||
        parseInt(this.props.company) === 11
      ) {
        return (
          <CardGroupamaGan
            key={this.props.company}
            data={
              parseInt(this.props.company) === 10 ||
              parseInt(this.props.company) === 11
                ? this.props.data
                : null
            }
          />
        );
      } else if (parseInt(this.props.company) === 13) {
        return (
          <CardABEILLE
            key={this.props.company}
            year={this.props.year}
            data={parseInt(this.props.company) === 13 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 16) {
        return (
          <CardGENERALI
            key={this.props.company}
            data={parseInt(this.props.company) === 16 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 19) {
        return (
          <CardAGPM
            key={this.props.company}
            data={parseInt(this.props.company) === 19 ? this.props.data : null}
          />
        );
      } else if (parseInt(this.props.company) === 20) {
        return (
          <CardAXA
            key={this.props.company}
            data={parseInt(this.props.company) === 20 ? this.props.data : null}
            isPrint={this.props.isPrint}
          />
        );
      } else if (parseInt(this.props.company) === 29) {
        return (
          <CardAllianzOM
            key={this.props.company}
            data={parseInt(this.props.company) === 29 ? this.props.data : null}
          />
        );
      }
    };

    return <div>{getComponent()}</div>;
  }
}

export default CardCompany;
